import React, { useState, useEffect } from "react";
import Card from "../../components/Cards";
import Layout from "../../components/Layout";
import EditIcon from "../../assets/svgs/pencil.inline.svg";
import OrderIcon from "../../assets/svgs/ordenacao.inline.svg";
import SEO from "../../components/SEO";
import InputSearch from "../../components/Input/InputSearch";
import ModalUserEdit from "./components/ModalUserEdit";
import ModalUserChangeRole from "./components/ModalUserChangeRole";
import ModalUserRegister from "./components/ModalUserRegister";
import requests from "../../services/requests";
import profile from "../../services/profile";
import Modal from "../../components/Modals";
import ModalUserRemove from "./components/ModalUserRemove";
import { ClipLoader } from "react-spinners";
import Paginate from "../../components/Paginate/Paginate";
import Select from "react-select";
import Button from "../../components/Button";
import { FiDownload } from "react-icons/fi";
import api from "../../services/api";

const UserManagement = () => {
    const [openTab, setOpenTab] = useState(profile.role === "ROLE_SYSTEM_ADMIN" ? "Administrador" : profile.role === "ROLE_TRIP_MANAGER" ? "Motorista" : "Operacional");
    const [load, setLoad] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openModalEdit, setOpenModalEdit] = useState(false);
    const [openModalRemove, setOpenModalRemove] = useState(false);
    const [openModalChangeRole, setOpenModalChangeRole] = useState(false);
    const [tabModal, setTabModal] = useState("Cadastro de perfil");
    const [tabModalEdit, setTabModalEdit] = useState("Edição de perfil");
    const [tabModalChangeRole, setTabModalChangeRole] = useState("Alterar tipo de usuário");
    const [tabModalRemove, setTabModalRemove] = useState("Remover perfil");
    const [list, setList] = useState([]);
    const [modalExport, setModalExport] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const [action, setAction] = useState(1);
    const [meta, setMeta] = useState({
        current_page: 1,
    });
    const [statusUser, setStatusUser] = useState("");
    const [filterSelectedStatus, setFilterSelectedStatus] = useState({ selectedStatus: null });
    const [filterSelectedAutoMode, setFilterSelectedAutoMode] = useState({ mode: "TODOS" });
    const [clientsOptions, setClientsOptions] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isLoadingClients, setIsLoadingClients] = useState(false);
    const [noClientOptionsMessage, setNoClientOptionsMessage] = useState("Nenhum resultado encontrado");
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);

    useEffect(() => {
        loadList();
    }, [openTab, searchString, action, filterSelectedStatus, statusUser, selectedClient, filterSelectedAutoMode]);

    const actionModalExport = () => {
        setModalExport(!modalExport);
    };

    const downloadCsv = () => {
        setIsDownloadingCsv(true);

        let role = [];
        if (openTab === "Administrador") {
            role = ["ROLE_SYSTEM_ADMIN"];
        } else if (openTab === "Operacional") {
            role = ["ROLE_OPERATOR"];
        } else if (openTab === "Cliente") {
            role = ["ROLE_CLIENT"];
        } else if (openTab === "Motorista") {
            role = ["ROLE_DRIVER"];
        } else if (openTab === "Comercial") {
            role = ["ROLE_COMERCIAL"];
        } else if (openTab === "Tributário") {
            role = ["ROLE_TAX_INSPECTOR"];
        } else if (openTab === "Financeiro") {
            role = ["ROLE_FINANCIAL_INSPECTOR"];
        } else if (openTab === "Diretoria") {
            role = ["ROLE_DIRECTOR"];
        } else if (openTab === "Logística") {
            role = ["ROLE_LOGISTIC"];
        } else if (openTab === "Gestor de viagens") {
            role = ["ROLE_TRIP_MANAGER"];
        }

        var params = "";
        if (searchString?.length > 0) {
            params += `search=${searchString}&`;
        }

        if (selectedClient != null) {
            params += `client=${selectedClient}&`;
        }

        if (filterSelectedStatus.selectedStatus?.length > 0) {
            params += `status=${filterSelectedStatus.selectedStatus}&`;
        }

        if (filterSelectedAutoMode.mode?.length > 0) {
            params += `auto=${filterSelectedAutoMode.mode}&`;
        }

        params += `&role[]=${role}`;

        api.get(`adm/user/export-csv?${params}`, {
            responseType: "blob",
            headers: {
                Authorization: "Bearer " + window.localStorage.getItem("session-token"),
            },
        })
            .then(response => {
                const fileType = response.headers["content-type"];
                const blob = new Blob([response.data], { fileType });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "export.csv");
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .finally(() => {
                setIsDownloadingCsv(false);
            });
    };

    const customStyles = {
        placeholder: () => ({
            color: "#bdc3d0",
            fontFamily: "inherit",
            fontSize: ".9rem",
            fontWeight: "none",
            paddingLeft: ".7rem",
        }),
    };

    const loadClientOptions = (search = "") => {
        setNoClientOptionsMessage("Carregando");
        setIsLoadingClients(true);

        if (search.length < 2) {
            setNoClientOptionsMessage("Insira ao menos 2 caracteres para fazer a busca");
            setIsLoadingClients(false);
        } else {
            requests
                .listAllClients(search)
                .then(res => {
                    var aux = [];
                    res.map(client => {
                        return(
                            aux.push({
                                value: client.identifier,
                                label: client.name,
                            })
                        )
                    });
                    setClientsOptions(aux);
                    setNoClientOptionsMessage("Nenhum resultado encontrado");
                })
                .finally(() => {
                    setIsLoadingClients(false);
                });
        }
    };

    const onChangeSearchString = e => {
        let search = e.target.value.trim();
        setSearchString("");
        if (search.length > 2) {
            setSearchString(search);
        }
    };

    const loadList = () => {
        setLoad(true);

        let role = [];
        if (openTab === "Administrador") {
            role = ["ROLE_SYSTEM_ADMIN"];
        } else if (openTab === "Operacional") {
            role = ["ROLE_OPERATOR"];
        } else if (openTab === "Cliente") {
            role = ["ROLE_CLIENT"];
        } else if (openTab === "Motorista") {
            role = ["ROLE_DRIVER"];
        } else if (openTab === "Comercial") {
            role = ["ROLE_COMERCIAL"];
        } else if (openTab === "Tributário") {
            role = ["ROLE_TAX_INSPECTOR"];
        } else if (openTab === "Financeiro") {
            role = ["ROLE_FINANCIAL_INSPECTOR"];
        } else if (openTab === "Diretoria") {
            role = ["ROLE_DIRECTOR"];
        } else if (openTab === "Logística") {
            role = ["ROLE_LOGISTIC"];
        } else if (openTab === "Gestor de viagens") {
            role = ["ROLE_TRIP_MANAGER"];
        }

        requests
            .listUsers(
                {
                    search: searchString,
                    filters: { ...filterSelectedStatus },
                    client: selectedClient,
                    role: role,
                    auto: { ...filterSelectedAutoMode },
                },
                meta.current_page > 0 ? meta.current_page : 1
            )
            .then(list => {
                setList(list.data);
                setMeta(list.meta);
                setLoad(false);
            });
    };

    const actionModal = () => {
        setOpenModal(!openModal);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    const actionModalEdit = user => {
        setCurrentUser(user);
        setOpenModalEdit(!openModalEdit);
    };

    const actionChangeRole = user => {
        setCurrentUser(user);
        setOpenModalChangeRole(!openModalChangeRole);
    };

    const actionModalRemove = user => {
        setCurrentUser(user);
        setOpenModalRemove(!openModalRemove);
    };

    const closeModalEdit = () => {
        setOpenModalEdit(false);
    };

    const closeModalRemove = () => {
        setOpenModalRemove(false);
    };

    const closeModalChangeRole = () => {
        setOpenModalChangeRole(false);
    };

    const actionTab = nameTab => {
        setOpenTab(nameTab);
        setMeta({
            current_page: 1,
        });
    };

    return (
        <Layout>
            <ModalUserRegister
                actionModal={closeModal}
                tabModal={tabModal}
                setTabModal={setTabModal}
                openModal={openModal}
                handleSuccess={() => {
                    closeModal();
                    loadList();
                }}
                tab={openTab}
            />
            <ModalUserEdit
                actionModal={() => {
                    closeModalEdit();
                    loadList();
                }}
                tabModal={tabModalEdit}
                setTabModal={setTabModalEdit}
                openModal={openModalEdit}
                selectedUser={currentUser}
                loadList={loadList}
            />
            <ModalUserRemove
                actionModal={closeModalRemove}
                tabModal={tabModalRemove}
                setTabModal={setTabModalRemove}
                openModal={openModalRemove}
                user={currentUser}
                statusUser={statusUser}
                handleSuccess={() => {
                    closeModalRemove();
                    loadList();
                }}
            />
            <ModalUserChangeRole
                actionModal={() => {
                    closeModalChangeRole();
                    loadList();
                }}
                tabModal={tabModalChangeRole}
                setTabModal={setTabModalChangeRole}
                setOpenModal={setOpenModalChangeRole}
                openModal={openModalChangeRole}
                selectedUser={currentUser}
                loadList={loadList}
            />
            <SEO title="Gestão de usuários" />
            <div className="p-4">
                <Card>
                    <div className="flex justify-between border-b border-C2 pb-4">
                        <div className="flex">
                            {profile.role === "ROLE_SYSTEM_ADMIN" && (
                                <>
                                    <button
                                        onClick={() => actionTab("Cliente")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Cliente" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Cliente
                                    </button>
                                    <button
                                        onClick={() => actionTab("Operacional")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Operacional" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Operacional
                                    </button>
                                    <button
                                        onClick={() => actionTab("Comercial")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Comercial" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Comercial
                                    </button>
                                    <button
                                        onClick={() => actionTab("Tributário")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Tributário" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Tributário
                                    </button>
                                    <button
                                        onClick={() => actionTab("Financeiro")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Financeiro" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Financeiro
                                    </button>
                                    <button
                                        onClick={() => actionTab("Diretoria")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Diretoria" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Diretoria
                                    </button>
                                    <button
                                        onClick={() => actionTab("Administrador")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Administrador" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Administrador
                                    </button>
                                </>
                            )}
                            {((process.env.REACT_APP_BASE_URL === "http://localhost:8000" || process.env.REACT_APP_BASE_URL === "http://localhost:8001" || process.env.REACT_APP_BASE_URL === "https://homolog-api-analytics.vendemmia.com.br") && (profile.role === "ROLE_SYSTEM_ADMIN" || profile.role === "ROLE_TRIP_MANAGER") && (
                                    <button
                                        onClick={() => actionTab("Motorista")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Motorista" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Motorista
                                    </button>
                                )
                            )}
                            {((process.env.REACT_APP_BASE_URL === "http://localhost:8000" || process.env.REACT_APP_BASE_URL === "http://localhost:8001" || process.env.REACT_APP_BASE_URL === "https://homolog-api-analytics.vendemmia.com.br") && profile.role === "ROLE_SYSTEM_ADMIN" && (
                                    <button
                                        onClick={() => actionTab("Gestor de viagens")}
                                        className={` mr-4 cursor-pointer ${
                                            openTab === "Gestor de viagens" ? "text-roxo_oficial" : "text-C6"
                                        }`}>
                                        Gestor de viagens
                                    </button>
                                )
                            )}
                        </div>
                        <div className="flex">
                            <button className="bg-roxo_oficial text-white px-4 py-1 rounded-md mr-4" onClick={actionModal}>
                                Cadastrar usuário
                            </button>
                            <Button
                                className="mr-4 ml-px font-medium h-10 bg-roxo_oficial"
                                onClick={() => setModalExport(true)}>
                                <FiDownload size="1.1rem" className="text-white" />{" "}
                                <div className="text-white ml-2">exportar</div>
                            </Button>
                        </div>
                    </div>
                    <div className="flex justify-between my-4">
                        <h5 className="uppercase text-C6">Usuários de perfil {openTab}</h5>
                        <div className="flex w-2/3 justify-between">
                            <Select
                                className="w-1/5 mr-4"
                                options={[
                                    { value: null, label: "TODOS" },
                                    { value: "ATIVO", label: "ATIVO" },
                                    { value: "EXPIRADO", label: "EXPIRADO" },
                                    { value: "INATIVO", label: "INATIVO" },
                                    { value: "ANONIMIZADO", label: "ANONIMIZADO" },
                                ]}
                                defaultValue={{ value: null, label: "TODOS" }}
                                onChange={item => {
                                    setFilterSelectedStatus({
                                        selectedStatus: item.value,
                                    });
                                }}
                            />
                            {openTab !== "Administrador" && openTab !== "Motorista" && (
                                <Select
                                    className="w-1/3 mr-4"
                                    options={[
                                        { value: "TODOS", label: "Sem filtro por vínculo" },
                                        { value: "ATIVO", label: "Com todos os clientes vinculados" },
                                        { value: "INATIVO", label: "Clientes vinculados manualmente" },
                                    ]}
                                    defaultValue={{ value: "TODOS", label: "Sem filtro" }}
                                    onChange={item => {
                                        setFilterSelectedAutoMode({
                                            mode: item.value,
                                        });
                                    }}
                                />
                            )}
                            {openTab !== "Administrador" && openTab !== "Motorista" && (
                                <Select
                                    className="w-2/5 outline-none transform-none mr-4"
                                    id="client"
                                    name="client"
                                    styles={customStyles}
                                    options={clientsOptions}
                                    onChange={item => {
                                        setSelectedClient(item.value);
                                    }}
                                    noOptionsMessage={() => noClientOptionsMessage}
                                    onInputChange={inputValue => {
                                        loadClientOptions(inputValue);
                                    }}
                                    isLoading={isLoadingClients}
                                    placeholder="buscar por cliente"
                                />
                            )}
                            <div className="w-2/5">
                                <InputSearch placeholder="buscar" className="h-8 w-48" onChange={onChangeSearchString} />
                            </div>
                        </div>
                    </div>

                    {load ? (
                        <div className="flex flex-col items-center">
                            <ClipLoader size={20} loading={load} />
                        </div>
                    ) : (
                        <table className="table-auto w-full">
                            <thead className="border-b border-roxo_oficial">
                                <tr>
                                    <th className="text-left min-w-5 text-roxo_oficial font-normal">Nome</th>
                                    <th className="text-left max-w-56 text-roxo_oficial font-normal">E-mail</th>
                                    {(openTab !== "Admin" && openTab !== "Diretoria") && (
                                        <th className="text-center max-w-56 text-roxo_oficial font-normal">
                                            Permissões concedidas
                                        </th>
                                    )}
                                    {(openTab !== "Admin" && openTab !== "Diretoria" && openTab !== "Gestor de viagens") && (
                                        <th className="text-center max-w-56 text-roxo_oficial font-normal">
                                            Empresas vinculadas
                                        </th>
                                    )}
                                    <th className="w-16 text-center text-roxo_oficial font-normal">Editar</th>
                                    <th className="w-40 text-center text-roxo_oficial font-normal">Status</th>
                                </tr>
                            </thead>
                            <tbody className="pt-2">
                                {list.map((data, key) => {
                                    return (
                                        <tr key={data.identifier}>
                                            <td className="text-left font-normal">{data.name}</td>
                                            <td className="text-left font-normal">{data.email}</td>
                                            {(openTab !== "Admin" && openTab !== "Diretoria") && (
                                                <td className="text-center font-normal">{data.permissionsQtt}</td>
                                            )}
                                            {(openTab !== "Admin" && openTab !== "Diretoria" && openTab !== "Gestor de viagens") &&
                                                (!data.hasAllClients ? (
                                                    <td className="text-center font-normal">{data.clientsQtt}</td>
                                                ) : (
                                                    <td className="text-center font-normal">
                                                        Todos os clientes ({data.clientsQtt})
                                                    </td>
                                                ))}
                                            <td className="w-40 text-center font-normal">
                                                <button
                                                    className="p-2 mt-2 rounded-md bg-roxo_oficial_light"
                                                    onClick={() => actionModalEdit(data)}
                                                    style={{width: '30px', height: '30px'}}
                                                    title="Editar usuário">
                                                    <EditIcon />
                                                </button>
                                                &nbsp;
                                                {(openTab !== "Cliente") &&
                                                    <button
                                                        className="p-2 rounded-md bg-roxo_oficial_light"
                                                        onClick={() => actionChangeRole(data)}
                                                        style={{width: '30px', height: '30px'}}
                                                        title="Alterar tipo de usuário">
                                                        <OrderIcon />
                                                    </button>
                                                }
                                            </td>
                                            <td className="w-16 text-center font-normal">
                                                <Select
                                                    options={[
                                                        { value: "ATIVO", label: "ATIVO" },
                                                        { value: "INATIVO", label: "INATIVO" },
                                                        { value: "EXPIRADO", label: "EXPIRADO" },
                                                        { value: "ANONIMIZADO", label: "ANONIMIZADO" },
                                                    ]}
                                                    defaultValue={{ value: data.status, label: data.status }}
                                                    isDisabled={data.status === "ANONIMIZADO"}
                                                    onChange={item => {
                                                        setStatusUser(item.value);
                                                        actionModalRemove(data);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                    {!load && list.length === 0 && <p class="text-center mt-3">Nenhum usuário encontrado</p>}
                    {meta.total_pages > 1 && (
                        <Paginate meta={meta} setMeta={setMeta} action={action} setAction={setAction} showDetails={true} />
                    )}
                </Card>
            </div>
            <Modal show={modalExport} borderTitle={false} onClose={actionModalExport} size="" title="">
                <div className="w-full mb-4 mr-4 text-center">
                    <h2 className="font-medium text-roxo_oficial">Exportar Usuários</h2>
                    <button
                        disabled={isDownloadingCsv}
                        onClick={downloadCsv}
                        className="mt-4 px-3 py-1 font-medium rounded h-10 w-24 bg-roxo_oficial text-white">
                        {isDownloadingCsv ? (
                            <>
                                <ClipLoader color="white" size={20} loading={isDownloadingCsv} /> CSV
                            </>
                        ) : (
                            <>
                                <FiDownload size="1.5rem" className="inline-block" /> CSV
                            </>
                        )}
                    </button>
                </div>
            </Modal>
        </Layout>
    );
};

export default UserManagement;