import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Modal from "../../../components/Modals";
import requests from "../../../services/requests";
import Select from "react-select";

const yup = require("yup");

const ModalUserChangeRole = ({ actionModal, tabModal, setTabModal, setOpenModal, openModal, selectedUser, loadList }) => {
    const [load, setLoad] = useState(false);
    const [user, setUser] = useState(null);
    const [newRole, setNewRole] = useState(null);

    useEffect(() => {
        setLoad(true);
        if (selectedUser && selectedUser.identifier) {
            requests.showUser(selectedUser.identifier).then(data => {
                setUser(data);
                setLoad(false);
            });
        }
    }, [selectedUser]);

    useEffect(() => {
        if (!openModal) {
            setUser(null);
        }
    }, [openModal]);

    const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

    const actionModalConfirmation = () => {
        setOpenModalConfirmation(!openModalConfirmation);

        requests
            .editUserRole(selectedUser.identifier, {
                role: newRole,
            })
            .then(() => {
                loadList();
                setOpenModal(!openModal);
                toast.success("Tipo de usuário alterado com sucesso!");
                setOpenModalConfirmation(!openModalConfirmation);
            })
            .catch(error => {
                console.log(error);
            })
        ;
    };

    return (
        <>
            <Modal
                onClose={actionModal}
                size={`xs:w-11/12 lg:w-6/12 min-h-80%`}
                show={openModal}
                title={
                    <div className="flex items-center">
                        <h5
                            className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}>
                            Alterar tipo de usuário
                        </h5>
                    </div>
                }>
                <>
                    {load ? (
                        <div className="flex flex-col items-center">
                            <ClipLoader size={20} loading={load} />
                        </div>
                    ) : (
                        <>
                            {user && (
                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        role: user.role,
                                    }}
                                    validationSchema={yup.object().shape({
                                        role: yup.string().required("Campo obrigatório"),
                                    })}
                                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                                        setSubmitting(true);

                                        setNewRole(values.role);
                                        setOpenModalConfirmation(true);
                                    }}>
                                    {({
                                        handleChange,
                                        handleSubmit,
                                        isSubmitting,
                                        errors,
                                        values,
                                        touched,
                                        handleBlur,
                                        setFieldValue,
                                    }) => (
                                        <form onSubmit={handleSubmit}>
                                            <div className="mx-5">
                                                <label htmlFor="name" className="text-roxo_oficial font-bold">
                                                    Perfil atual:
                                                </label>
                                                <b>&nbsp;{user.roleHumanReadable}</b>

                                                <hr />

                                                <label htmlFor="cellphone" className="text-roxo_oficial font-bold mt-4">
                                                    Novo tipo
                                                </label>
                                                <Select
                                                    className="w-full mr-4"
                                                    options={[
                                                        { value: "ROLE_SYSTEM_ADMIN", label: "Administrador" },
                                                        { value: "ROLE_OPERATOR", label: "Operador" },
                                                        { value: "ROLE_CLIENT", label: "Cliente" },
                                                        { value: "ROLE_DRIVER", label: "Motorista" },
                                                        { value: "ROLE_COMERCIAL", label: "Comercial" },
                                                        { value: "ROLE_TAX_INSPECTOR", label: "Tributário" },
                                                        { value: "ROLE_FINANCIAL_INSPECTOR", label: "Financeiro" },
                                                        { value: "ROLE_DIRECTOR", label: "Diretoria" },
                                                    ]}
                                                    defaultValue={{ value: user.role, label: user.roleHumanReadable }}
                                                    onChange={item => {
                                                        setFieldValue("role", item?.value);
                                                    }}
                                                />
                                            </div>
                                            <button
                                                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                                                type="submit"
                                                disabled={isSubmitting}>
                                                {isSubmitting ? (
                                                    <>
                                                        Salvando <ClipLoader size={10} loading={isSubmitting} />
                                                    </>
                                                ) : (
                                                    <>Salvar</>
                                                )}
                                            </button>
                                        </form>
                                    )}
                                </Formik>
                            )}
                        </>
                    )}
                </>
            </Modal>
            <Modal onClose={actionModalConfirmation} size="w-1/4" show={openModalConfirmation} title="Adicionar todos">
                <p>Deseja realmente alterar o tipo de perfil do usuário?</p>
                <button
                    className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                    type="submit"
                    onClick={() => {
                        actionModalConfirmation();
                    }}>
                    Confirmar
                </button>
            </Modal>
        </>
    );
};
export default ModalUserChangeRole;
