import React from "react";
import { Formik, ErrorMessage } from "formik";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import Modal from "../../../components/Modals";
import Input from "../../../components/Input/Input";
import InputMasked from "../../../components/Input/InputMasked";
import requests from "../../../services/requests";

const yup = require("yup");

const ModalUserRegister = ({ actionModal, tabModal, setTabModal, openModal, tab, handleSuccess }) => {
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12 min-h-40%"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5 onClick={() => setTabModal("Cadastro de usuário")} className={`mr-6 text-roxo_oficial font-bold`}>
                        Cadastro de usuário {tab}
                    </h5>
                </div>
            }
        >
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        name: "",
                        email: "",
                        cellphone: "",
                        phone: "",
                        password: "",
                    }}
                    validationSchema={yup.object().shape({
                        name: yup.string().required("Campo obrigatório"),
                        email: yup.string().required("Campo obrigatório").email("Deve ser um e-mail válido"),
                        password: yup
                            .string()
                            .required("Campo obrigatório")
                            .min(6, "A senha deve conter no mínimo 6 caracteres"),
                        cellphone: yup.string(),
                        phone: yup.string(),
                    })}
                    onSubmit={(values, { setFieldError, setSubmitting }) => {
                        setSubmitting(true);

                        let modality = "";
                        if (tab === "Administrador") {
                            modality = "admin";
                        } else if (tab === "Operacional") {
                            modality = "operator";
                        } else if (tab === "Cliente") {
                            modality = "client";
                        } else if (tab === "Motorista") {
                            modality = "driver";
                        } else if (tab === "Comercial") {
                            modality = "comercial";
                        } else if (tab === "Tributário") {
                            modality = "tax";
                        } else if (tab === "Financeiro") {
                            modality = "financial";
                        } else if (tab === "Diretoria") {
                            modality = "director";
                        } else if (tab === "Gestor de viagens") {
                            modality = "trip-manager";
                        }

                        requests
                            .addUser(modality, {
                                name: values.name,
                                email: values.email,
                                cellphone: values.cellphone,
                                phone: values.phone,
                                password: values.password,
                            })
                            .then(response => {
                                setSubmitting(false);
                                handleSuccess();
                                toast.success("Usuário " + response.name + " cadastrado com sucesso!");
                            })
                            .catch(error => {
                                setSubmitting(false);
                            });
                    }}
                >
                    {({ handleChange, handleBlur, handleSubmit, isSubmitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div className="mx-10">
                                <label htmlFor="name" className="text-roxo_oficial font-bold">
                                    Nome completo
                                </label>
                                <Input name="name" id="name" onChange={handleChange} value={values.name} />
                                <ErrorMessage component="label" name="name" className="text-red font-light w-full" />

                                <label htmlFor="cellphone" className="text-roxo_oficial font-bold mt-4">
                                    Celular <small>(opcional)</small>
                                </label>
                                <InputMasked
                                    name="cellphone"
                                    id="cellphone"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.cellphone}
                                    mask="(99) 99999-9999"
                                />
                                <ErrorMessage component="label" name="cellphone" className="text-red font-light w-full" />

                                <label htmlFor="phone" className="text-roxo_oficial font-bold mt-4">
                                    Telefone <small>(opcional)</small>
                                </label>
                                <InputMasked
                                    name="phone"
                                    id="phone"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.phone}
                                    mask="(99) 9999-9999"
                                />
                                <ErrorMessage component="label" name="phone" className="text-red font-light w-full" />

                                <label htmlFor="email" className="text-roxo_oficial font-bold mt-4">
                                    E-mail
                                </label>
                                <Input name="email" id="email" onChange={handleChange} value={values.email} />
                                <ErrorMessage component="label" name="email" className="text-red font-light w-full" />

                                <label htmlFor="password" className="text-roxo_oficial font-bold mt-4">
                                    Senha de acesso
                                </label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    onChange={handleChange}
                                    value={values.password}
                                />
                                <ErrorMessage component="label" name="password" className="text-red font-light w-full" />
                            </div>
                            <button
                                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        Salvando <ClipLoader size={10} loading={isSubmitting} />
                                    </>
                                ) : (
                                    <>Salvar</>
                                )}
                            </button>
                        </form>
                    )}
                </Formik>
            </>
        </Modal>
    );
};

export default ModalUserRegister;
