import React from "react";
import Modal from "../../../components/Modals";
import requests from "../../../services/requests";
import { toast } from "react-toastify";

const ModalUserRemove = ({ actionModal, tabModal, setTabModal, openModal, tab, handleSuccess, user, statusUser }) => {
    return (
        <Modal
            onClose={actionModal}
            size="xs:w-11/12 lg:w-4/12"
            show={openModal}
            title={
                <div className="flex items-center">
                    <h5
                        onClick={() => setTabModal("Alterar Usuário")}
                        className={`mr-6 cursor-pointer text-roxo_oficial font-bold`}
                    >
                        Confirma a alteração do status de {user?.name} ({user?.email})
                    </h5>
                </div>
            }
        >
            {statusUser === "ATIVO" && (
                <p className="text-center">O usuário será reativado no sistema, podendo se autenticar novamente.</p>
            )}
            {statusUser === "EXPIRADO" && (
                <p className="text-center">
                    O usuário perderá acesso á plataforma e verá um alerta ao entar se autenticar, solicitando que entre em
                    contato com a Vendemmia.
                </p>
            )}
            {statusUser === "ANONIMIZADO" && (
                <>
                    <h3 className="text-center">Atenção: esta ação é permanente!</h3>
                    <p className="text-center">
                        Todos os dados pessoais desse usuário (nome, email, senha, telefones e etc) serão anonimizados e
                        perdidos.
                    </p>
                </>
            )}
            {statusUser === "INATIVO" && (
                <p className="text-center">O usuário não conseguirá mais se autenticar na plataforma.</p>
            )}
            {user?.requestedQuotes.length > 0 && (
                <div className="mb-4">
                    <p className="text-center">Orçamentos criados por este usuário:</p>
                    {user?.requestedQuotes.map(quote => (
                        <p className="text-center">- {quote.name}</p>
                    ))}
                </div>
            )}
            {user?.commentsQtt + user?.filesQtt > 0 && (
                <div className="mb-4">
                    <p className="text-center">O usuário possui:</p>
                </div>
            )}
            {user?.commentsQtt > 0 && (
                <div className="mb-4">
                    <p className="text-center">
                        {user.commentsQtt === 1 ? "1 comentário deste usuário" : `${user.commentsQtt} comentários.`}
                    </p>
                </div>
            )}
            {user?.filesQtt > 0 && (
                <div className="mb-4">
                    <p className="text-center">
                        {user.filesQtt === 1 ? "1 arquivo enviado por este usuário" : `${user.filesQtt} arquivos enviados.`}
                    </p>
                </div>
            )}

            <button
                className="mb-4 bg-roxo_oficial text-white py-2 px-5 rounded-md float-right mt-10"
                onClick={() => {
                    requests.deleteUser(user.identifier, { statusUser }).then(() => {
                        toast.success("Usuário " + user.name + " foi " + statusUser + " com sucesso!");
                        handleSuccess();
                    });
                }}
            >
                <>Confirmar</>
            </button>
        </Modal>
    );
};
export default ModalUserRemove;
